////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useEffect, useState } from "react";
import "../styles/checkout.css";
import { Link, useLocation } from "react-router-dom";
import { useID } from "../contexts/TicketInformationProvider";
import Button from "react-bootstrap/Button";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Validating the entered date or age of the user
const isDateInputValid = (age, date) => {
  if (age) {
    const agePattern = /^[1-9]?[0-9]{1}$|^100$/;
    if (!agePattern.test(age)) throw new Error("not a valid age!");
    return true;
  } else {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    if (date < currentDate || date.getFullYear() > 2028)
      throw new Error("not a valid date!");
    return true;
  }
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the TicketData component
function TicketData() {
  const { updateTicketInformation } = useID();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState();
  const [ageInput, setAgeInput] = useState("0000-00-00");
  const errorElement = document.getElementById("validationError");

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Method to check the email validity
  const isEmailValid = () => {
    return document.getElementById("email").validity.valid;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Calculating the age of the user and hence returning him to different routes
  function ageValidation(userDate) {

    //Checking wether the email-input is valid
    const today = new Date();
    const birthDate = new Date(userDate);

    // Get the difference in years
    let age = today.getFullYear() - birthDate.getFullYear();

    // Check if the birthday has occurred this year already
    const hasBirthdayPassed =
      today.getMonth() > birthDate.getMonth() ||
      (today.getMonth() === birthDate.getMonth() &&
        today.getDate() >= birthDate.getDate());

    if (!hasBirthdayPassed) {
      age--;
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    //Checks if the user is older than 18, as well as if the user entered an age, if not, the button will redirect to the current page.
    if (!isDateInputValid(age)) {
      errorElement.textContent =
        "Diese Altersangabe is inkorrekt. Versuche es erneut";
      throw new Error("Alterseingabe falsch");
    }
    if (age > 17) {
      return "/createstripesession";
    } else {
      if (age > 13) {
        return "/muttizettel?buyingTicket";
      } else {
        return "/ticketdata";
      }
    }
  }
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Updating the eventcontext object with the entered user credentials
  const updateEventContext = async (event) => {

    //Preventing any actions if the email is invalid
    if (!isEmailValid()) return;
    await updateTicketInformation({
      guestName: name,
      emailAddress: email,
      hasSentEmail: false,
      age: ageInput,
      payment: false,
    });

    window.location.href = ageValidation(ageInput);

  };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Requesting the server to add the user to the database
  async function handleSubmit(event) {
    event.preventDefault();

    const userData = {
      name: document.getElementById("nameBox").value,
      email: document.getElementById("email").value,
      age: document.getElementById("age").value,
    };

    fetch(`${process.env.REACT_APP_SERVER_API_URL}/postUserData`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(userData),
    })
      .then(() => {
        console.log("Added User!");
      })
      .catch((error) =>
        console.log("Could not send user to Database! Try again")
      );
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component
  return (
    <div className="checkoutPage">
      <form onSubmit={handleSubmit} className="contactDataForm">
        <input
          required
          onChange={(event) => setName(event.target.value)}
          id="nameBox"
          type="text"
          placeholder="Vollständiger Name"
        />
        <input
          required
          id="email"
          onChange={(event) => setEmail(event.target.value)}
          type="email"
          placeholder="Email"
        />
        <label htmlFor="age">Geburtsdatum:</label>
        <input
          defaultValue={ageInput}
          onChange={(event) => setAgeInput(event.target.value)}
          required
          id="age"
          type="date"
          placeholder="Date of Birth"
          style={{ color: "#111111" }}
        />
        <Button
          className="BuyTicketButton"
          style={{ width: "95%", height: "17%", marginRight: "20%" }}
          disabled={loading}
          onClick={updateEventContext}
          type="submit"
        >
          Weiter zur Bezahlung
        </Button>
        <p color="red" id="validationError"></p>
      </form>
    </div>
  );
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports
export default TicketData;
export { isDateInputValid };