///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "../styles/components.css";


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a component which shows the guest information and checks in the guest
//Only members of the event staff can check in the guest with a unique password, since otherwise the guest could also be checked in by himself or third parties
function CheckInGuest() {

  //Declaring state variables holding the event & input password, errors and the guest himself in an object
  const [eventPassword, setEventPassword] = useState("");
  const [inputPassword, setInputPassword] = useState("");
  const [error, setError] = useState(false);
  const [guest, setGuest] = useState({
    eventID: "",
    eventName: "",
    guestName: "",
    email: "",
    age: 0,
    paymentStatus: false,
  });

  //Getting the URL parameters and storing them in an array
  const windowLocation = useLocation();
  // const parameters = new URLSearchParams(windowLocation.search).values();
  // const arrayOfParameters = Array.from(parameters);
  const parameters = new URLSearchParams(windowLocation.search);


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Updating the guest information only when the component mounts
  //UseEffect is needed, otherwise the component would always rerender when any of the state variables change (Here the guest, setGuest state variable)
  useEffect(() => {
    //prevents an infinite loop => only sets data when the component mounts
    setGuest({
      eventID: parameters.get('eventID'),
      eventName: parameters.get('eventName'),
      eventGuest: parameters.get('guestName'),
      email: parameters.get('emailAddress'),
      age: parameters.get('age'),
      paymentStatus: "true",
    });
    fetch(`${process.env.REACT_APP_SERVER_API_URL}/getPwd`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ eventID: parameters.get("eventID")}), //Change made here. Before : eventID: "nKwPKvqDpeDYVWhpJ1dJ"
    })
      .then((res) => {
        if (res.ok) return res.json();
      })
      .then((data) => setEventPassword(data.password));
  }, []);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Handling the check in by validating that the event passwords matches the input password 
  const handleCheckIn = (event) => {
    event.preventDefault();

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//Checking the event password. If successful the guest is checked in and added to the event session    

if (inputPassword === eventPassword) {
      //If the passwords match, the guest is added to the database collection of the event sessions and is then redirected to the guestlist route
      try {
        const guestObject = {
          eventName: parameters.get('eventName'),
          guestName: parameters.get('guestName'),
          guestData: {
            email: parameters.get('emailAddress'),
            age: parameters.get('age'),
            paymentStatus: "true",
          },
        };
        fetch(
          `${process.env.REACT_APP_SERVER_API_URL}/addGuestToEventSession`,
          {
            method: "POST",
            body: JSON.stringify(guestObject),
            headers: { "Content-Type": "application/json" },
          }
        )
          .then(document.getElementById("successfullyCheckedIn").style.display = "block") //Display the successmessage || Make the element visible
      } catch (e) {
        console.error("The following error occured: ", e);
      }
    } else {
      setError(true);
    }
  };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining an ErrorMessage component with the message that the passwords do not match and hence the user is not checked in and added to the event session
  function ErrorMessage() {
    return (
      <div>
        <p style={{ color: "red" }}>
          Password ist inkorrekt!. Über das Passwort verfügt nur der
          Veranstalter und Mitveranstalter!
        </p>
      </div>
    );
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component  
  return (
    <div class="form-container">
      <form class="form">
        <div class="form-group">
          <label for="email">EventID</label>
          <input id="event-id" readOnly placeholder={guest["eventID"]} />
        </div>
        <div class="form-group">
          <label for="email">Event</label>
          <input
            placeholder={guest["eventName"]}
            id="event-name"
            name="email"
            readOnly
          />
        </div>
        <div class="form-group">
          <label for="email">Gast-Name</label>
          <input
            id="guest-name"
            name="email"
            readOnly
            placeholder={guest["eventGuest"]}
          />
        </div>
        <div class="form-group">
          <label for="email">Email</label>
          <input
            id="email"
            name="email"
            readOnly
            placeholder={guest["email"]}
          />
        </div>
        <div class="form-group">
          <label for="email">Geburtsdatum / Alter</label>
          <input
            id="birth-date"
            name="email"
            readOnly
            placeholder={guest["age"]}
          />
        </div>
        <div class="form-group">
          <label for="email">Zahlung getätigt</label>
          <input
            id="payment-status"
            name="email"
            readOnly
            placeholder={guest["paymentStatus"]}
          />
        </div>
        <div class="form-group">
          <label for="email">Veranstaltungspasswort</label>
          <input
            onChange={(char) => setInputPassword(char.target.value)}
            id="email"
            name="email"
            type="password"
          />
        </div>
        {error ? <ErrorMessage /> : null}

        <button onClick={handleCheckIn} class="form-submit-btn" type="submit">
          Check-In
        </button>
        <p id="successfullyCheckedIn" style={{color: "green", display: "none", marginLeft: "23%"}}>Erfolgreich eingecheckt!</p>
      </form>
    </div>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//exports:
export default CheckInGuest;
