////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useRef, useState, useEffect } from "react";
import "../styles/muttizettel.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the siganture strings holding the Data URL of the Canvas Snapshot || Variable for checking if the user already signed
var chaperoneSignature = "";
var parentsSignature = "";
var hasSignature = false;

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the SignaturePad component
function SignaturePad(props) {

  //Referencing the canvas html element and Defining a state, checking if the user is currently signing
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);

//Defining a function to diable the scrolling on the page, as soon as the user starts signing
  const disableScroll = () => {
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";
  };

//Defining a function to enable the scrolling on the page, as soon as the user stops signing
  const enableScroll = () => {
    document.body.style.overflow = "auto";
    document.documentElement.style.overflow = "auto";
  };

//Checking wether the user is on a mobile device or not by identifying if there are any touchpoints on the device
  const isMobileDevice = () => {
    return navigator.maxTouchPoints > 0;
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a function get ready to draw by identifying the position of the canvas and setting up the drawing components || Disabling the scroll, if the user is on a mobile device
  const startDrawing = (event) => {
    if (!isMobileDevice()) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.strokeStyle = "rgb(40, 40, 40)";

      const canvasRect = canvas.getBoundingClientRect();
      const offsetX = event.clientX - canvasRect.left;
      const offsetY = event.clientY - canvasRect.top;

      context.beginPath();
      context.moveTo(offsetX, offsetY);
      setIsDrawing(true);
      hasSignature = true;
    } else {
      disableScroll();
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.strokeStyle = "rgb(40, 40, 40)";

      const canvasRect = canvas.getBoundingClientRect();
      const offsetX = event.touches[0].clientX - canvasRect.left;
      const offsetY = event.touches[0].clientY - canvasRect.top;

      context.beginPath();
      context.moveTo(offsetX, offsetY);
      setIsDrawing(true);
      hasSignature = true;
    }

    // Add global touchmove event listener to prevent page scrolling
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a function to draw a line by connnecting all the points of the users cursor on the canvas and stopping if the user is not drawing || Disabling the scroll, if the user is on a mobile device
  const draw = (event) => {
    if (!isDrawing) return;

    if (!isMobileDevice()) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      const canvasRect = canvas.getBoundingClientRect();
      const offsetX = event.clientX - canvasRect.left;
      const offsetY = event.clientY - canvasRect.top;

      context.lineTo(offsetX, offsetY);
      context.stroke();
    } else {
      disableScroll();
      if (!isDrawing) return;

      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");

      if (event.touches && event.touches.length > 0) {
        const canvasRect = canvas.getBoundingClientRect();
        const offsetX = event.touches[0].clientX - canvasRect.left;
        const offsetY = event.touches[0].clientY - canvasRect.top;

        context.lineTo(offsetX, offsetY);
        context.stroke();
      }
    }
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Handling the case, if the user stops signing and enabling scrolling again
  const stopDrawing = () => {
    enableScroll();
    setIsDrawing(false);
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
// Clears the pad if the user wants to draw a new signature.
  const clearPad = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
  };
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Saving the signature as a DataURL in the chaperoneSignature and parentsSignature variables || Differentiate between the two signing pads by giving each pad a unique ID
  const saveSignature = () => {
    const padID = props.padID;
    const canvas = canvasRef.current;

    if (padID === "chaperoneSignature") {
      chaperoneSignature = canvas.toDataURL("image/png");
    } else {
      parentsSignature = canvas.toDataURL("image/png");
    }
    console.log(padID, ": ", chaperoneSignature, " || ", parentsSignature);
  };

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up of the canvas, the save and the clear buttons || Giving the canvas the right attributes
  return (
    <>
      <Container className="signature-canvas">
        <canvas
          ref={canvasRef}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onTouchStart={startDrawing}
          onTouchMove={draw}
          onTouchEnd={stopDrawing}
        />
      </Container>
      <Button
        style={{ backgroundColor: "rgb(25, 180, 222)", color: "#111111" }}
        className="saveButton"
        onClick={saveSignature}
      >
        Speichern
      </Button>
      <Button
        style={{ backgroundColor: "rgb(25, 180, 222)", color: "#111111" }}
        className="clearButton"
        onClick={clearPad}
      >
        Löschen
      </Button>
      <div id="displaySignature"> </div>
    </>
  );
}

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports:
export { chaperoneSignature, parentsSignature, hasSignature };
export default SignaturePad;
