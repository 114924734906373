////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

import { useState } from "react";
import "../styles/muttizettel.css";
import SignaturePad from "./SignaturePad.jsx";
import { chaperoneSignature, parentsSignature } from "./SignaturePad.jsx";
import Button from "react-bootstrap/Button";
import { isDateInputValid } from "./TicketData.jsx";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the Muttizettel component
function Muttizettel() {

  //Declaring a useState Hook in order to track errors in the application and handle them if needed
  const [error, setError] = useState(false);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Function to check if the user is buying a ticket by checking if there are URL parameters sent over
  const hasURLParameters = (urlString) => {
    const parsedUrl = new URL(urlString);
    return parsedUrl.searchParams && parsedUrl.searchParams.toString() !== "";
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Function checking if the entered phone number resembles a valid phone number pattern
  const isPhoneNumberValid = () => {
    //Weglassen und in den Submit einbauen!
    const phoneNumberInput =
      document.getElementById("parentsPhoneNumber").value;

    //Phonenumber pattern which only accepts valid german and luxemburgish numbers
    const validPattern = /^(\+49|0049|\+352|00352)[1-9][0-9]{1,14}$/;
    if (!validPattern.test(phoneNumberInput)) {
      return false;
    } else {
      return true;
    }
  };

  //Fetching the form data in order to store it in a database and later retrieve it 
  function handleSubmit() {
    const parentsName = document.getElementById("parentsName").value;
    const underageName = document.getElementById("underageName").value;
    const underageBirthdate =
      document.getElementById("underageBirthdate").value;
    const chaperone = document.getElementById("chaperone").value;
    const eventDate = document.getElementById("eventDate").value;
    const timeLimit = document.getElementById("timeLimit").value;
    const eventPlace = document.getElementById("eventPlace").value;
    const parentsPhoneNumber =
      document.getElementById("parentsPhoneNumber").value;
    const dateOfSubmit = document.getElementById("dateOfSubmit").value;

    const today = new Date();
    const birthDate = new Date(underageBirthdate);
    // Get the difference in years
    let underAge = today.getFullYear() - birthDate.getFullYear();

    const eventDate2 = new Date(eventDate);
    const dateOfSubmit2 = new Date(dateOfSubmit);

    const userData = {
      parentsName,
      underageName,
      underageBirthdate,
      chaperone,
      eventDate,
      timeLimit,
      eventPlace,
      parentsPhoneNumber,
      chaperoneSignature,
      parentsSignature,
      dateOfSubmit,
      buyingTicket: hasURLParameters(window.location.href),
    };
    //Bessere Lösung finden => Zu viel Wiederholung
    //Checking if the user filled out every field
    if (
      !parentsName ||
      !underageName ||
      !underageBirthdate ||
      !chaperone ||
      !eventDate ||
      !timeLimit ||
      !eventPlace ||
      !parentsPhoneNumber ||
      !chaperoneSignature ||
      !parentsSignature ||
      !dateOfSubmit ||
      !isPhoneNumberValid() ||
      !isDateInputValid(underAge, 0) ||
      !isDateInputValid(0, eventDate2) ||
      !isDateInputValid(0, dateOfSubmit2)
    ) {
      setError(true);
    } else {
      setError(false);
      fetch(`${process.env.REACT_APP_SERVER_API_URL}/postUserData`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(userData),
      })
        .then((res) => {
          console.log("Added User! ", res);
        })
        .catch((error) =>
          console.log("Could not send user to Database! Try again")
        );

      ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      
      //Passing the form data into the URL parameters of the next route 
      const urlParams = new URLSearchParams(userData);
      const parseUrl = urlParams.toString();
      window.document.location = `/sendmuttizettel?isBuyingTicket=${userData.buyingTicket}&${parseUrl}`;
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Creating a component to show an error message if entered values are incorrect
  function ErrorMessage() {
    if (error) {
      return (
        <p className="errorMessage" style={{ marginLeft: "0" }}>
          Bestimmte Angaben sind inkorrekt oder fehlen!
        </p>
      );
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  //Returning the mark up to the component
  return (
    <div className="container">
      <h1 className="headers">Erziehungsbeauftragung</h1>
      <h2 className="headers">Muttizettel</h2>
      <h3 className="headers">Gemäß § 1 Abs. 1 Nr. 4 Jugendschutzgesetz</h3>
      <p className="text">
        Hiermit erkläre ich, <br />
        <input
          id="parentsName"
          className="inputFields"
          type="text"
          placeholder="Vollständiger Name Erziehungsberechtige(r) (z.B Eltern)"
        />
        <br />
        dass für den Minderjährigen <br />
        <input
          id="underageName"
          className="inputFields"
          type="text"
          placeholder="Vollständiger Name Minderjährige(r)"
        />
        <label htmlFor="underageBirthdate">
          Geburtsdatum Minderjährige(r):{" "}
        </label>
        <br />
        <input
          id="underageBirthdate"
          className="inputFields"
          type="date"
          min="1900-01-01"
          max="2025-12-31"
          placeholder="Geburtsdatum Minderjährige(r)"
        />
        <br />
        von <br />
        <input
          id="chaperone"
          className="inputFields"
          type="text"
          placeholder="Vollständiger Name Erziehungsbeauftragte(r) (z.B volljährige Geschwister)"
        />
        <br />
        Erziehungsaufgaben im unten aufgeführten Umfang übernommen werden.
        <br /> <br />
        Ich kenne die beauftragte Person und vertraue ihr die erzieherische
        Führung des Minderjährigen an. Die beauftragte Person ist 18 Jahre oder
        älter und hat genug erzieherische Kompetenzen, um einen Minderjährigen
        Grenzen setzen zu können, im Besonderen hinsichtlich des Alkoholkonsums.
        Er/Sie trägt Sorge dafür, das die/der Minderjährige zur angegebenen Zeit
        die Veranstaltung verlässt und unversehrt zu Hause ankommt. Dies
        bestätigt die/der Erziehungsbeauftragte mit ihrer/seiner Unterschrift.
        <br />
        <br />
        Unterschrift Erziehungsbeauftragte(r){" "}
        <SignaturePad padID="chaperoneSignature" />
        <p style={{ color: "red" }}>
          Die Unterschrift muss vor der Abgabe gespeichert werden!
        </p>
      </p>
      <p className="text">
        Diese Beauftragung gilt
        <br />
        <label htmlFor="eventDate">Datum der Veranstaltung: </label>
        <br />
        <input
          id="eventDate"
          className="inputFields"
          type="date"
          min="1900-01-01"
          max="2025-12-31"
          placeholder="am (Datum d. Veranstaltung)"
        />
        <br />
        <label htmlFor="timeLimit">bis (Uhrzeit)</label>
        <br />
        <input
          id="timeLimit"
          className="inputFields"
          type="time"
          placeholder="bis (Uhrzeit)"
        />{" "}
      </p>
      <input
        id="eventPlace"
        className="inputFields"
        type="text"
        placeholder="für folgende(n) Ort(e)/Veranstaltung(en)"
      />
      <br />
      <br />
      <p className="text">
        Unterschrift Erziehungsberechtige(r)
        <SignaturePad padID="parentsSignature" />
        <p style={{ color: "red" }}>
          Die Unterschrift muss vor der Abgabe gespeichert werden!
        </p>
        <p className="text">
          <label htmlFor="dateOfSubmit">Aktuelles Datum:</label>
          <br />
          <input
            className="inputFields"
            type="date"
            min="1900-01-01"
            max="2025-12-31"
            id="dateOfSubmit"
            placeholder="Ort/Datum"
          />{" "}
          <br />
        </p>
        <input
          id="parentsPhoneNumber"
          className="inputFields"
          type="phone"
          pattern="^(\+49\s|0)\d{3,4}\s\d{7,8}$"
          placeholder="Telefonnummer Erziehungsberechtige(r) für Rückfragen"
        />
        <span id="phoneNumberError" style={{color: "red"}}>
          Die Telefonnummer ist ungültig. Nur deutsche Nummern werden
          akzeptiert!
        </span>
        <p className="rechtlicheWarnung">
          Die Fälschung einer Unterschrift stellt nach §267 StGB eine Straftat
          dar. Auch der Versuch ist strafbar!
        </p>
        <p className="text">
          Haftungsausschluss: party-ticket.com übernimmt keine Haftung für die
          Verwendung dieses Dokumentes und kann nicht für den Zutritt zu
          Veranstaltungen garantieren. Hinweis: In einigen Landkreisen dürfen
          generell nur noch die Vordrucke des jeweiligen Landkreises verwendet
          werden!
        </p>
      </p>
      <Button
        id="muttizettelSubmit"
        style={{ backgroundColor: "rgb(25, 180, 222)", color: "#111111" }}
        className="submitButton"
        type="submit"
        onClick={handleSubmit}
      >
        Muttizettel speichern
      </Button>
      <ErrorMessage />
    </div>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//exports
export default Muttizettel;
