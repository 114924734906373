///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import "../styles/checkout.css";
import { useID } from "../contexts/TicketInformationProvider";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a component which creates a stripe payment session with the given guest information
function CreateStripeSession() {

  //Initializing the ticket information context and the updateTicketInformation procedure
  const { ticketInformation, updateTicketInformation } = useID();

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating a timeout which waits 33 Minutes until it is activated and returns the user to the timeout component
//This is because the stripe session expires after this time and cannot be used anymore

  const timeout = () => {
    window.location.href = "/timeout";
  };
  setTimeout(timeout, 2000000); // After 33 Minutes the sessions expires and sends the user first to /timeout => Home Page
  
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Transfering the checkout data to the server which creates a stripe session 
//The url of the stripe session is the response of the server => The user is then redirected to this url in order to make the payment
  function transferCheckoutData(e) {
    e.preventDefault();
    fetch(`${process.env.REACT_APP_SERVER_API_URL}/createStripeSession`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: ticketInformation["eventID"],
      }),
    })
      .then((res) => {
        if (res.ok) return res.json();
        return res.json().then((json) => Promise.reject(json));
      })

      .then(({ url }) => {
        window.location.href = url;
      })
      .catch((e) => {
        console.error(e.error);
      });
  }
  
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component
  return (
    <>
      <div className="form-container">
        <h1 className="stripe-session-header">
          {ticketInformation["eventName"]}
        </h1>
        <hr />
        <form className="form">
          <div className="form-group">
            <label for="email">Name</label>
            <input
              id="email"
              readOnly
              value={ticketInformation["guestName"]}
            />{" "}
            <br />
            <label for="email">Email-Adresse</label>
            <input
              id="email"
              readOnly
              value={ticketInformation["emailAddress"]}
            />
            <br />
            <label for="email">Geburtsdatum</label>
            <input id="email" readOnly value={ticketInformation["age"]} />
            <br />
            <hr />
            <p style={{ textAlign: "center" }}>
              {" "}
              {ticketInformation["priceInCents"] / 100}€{" "}
            </p>
            <button
              onClick={transferCheckoutData}
              style={{
                color: "black",
                marginLeft: "21%",
                marginTop: "0.5rem",
                marginBottom: "1rem",
              }}
              className="BuyTicketButton"
            >
              Bezahlen
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//exports
export default CreateStripeSession;
