import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, addDoc, getDoc, doc, setDoc } from "firebase/firestore";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Initialize the firebase application with it's credentials

const app = initializeApp({
  apiKey: "AIzaSyBWj5R4gWSlplkSqIpfuM544ZgrNeXhEUs",

  authDomain: "pt-development-25013.firebaseapp.com",

  projectId: "pt-development-25013",

  storageBucket: "pt-development-25013.appspot.com",

  messagingSenderId: "283698351563",

  appId: "1:283698351563:web:b7fe1d685fa4f9eccdfa4e",
  
  measurementId: "G-NXKCT0QSSW"
});

//Initilaize Firestore Database to the "db" constant

const db = getFirestore();

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Create a Reference to the "userCheckout" database collection

const userCheckoutCollectionReference = collection(db, "userCheckout");
const eventsCollectionReference = collection(db, "Events")
const eventSessionsCollectionReference = collection(db, "EventSessions")
const muttizettelCollectionReference = collection(db, "Muttizettel")

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Queries

const getEvents = async() =>{
  try{
    const querySnapshot = await getDocs(eventsCollectionReference)
    const events = [];
    querySnapshot.forEach((event) => {
      events.push({ 
        eventID: event.id,
        eventName: event.data().name,
        date: event.data().date,
        location: event.data().location,
        priceInCents: event.data().priceInCents,
        imgSrc: event.data().imgSrc
      })
    })
    return events
  } catch(error) {
    console.error("An error occurred while fetching the event data:", error);
    throw error; // Rethrow the error for the calling function to handle
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Get all users from the database

const getAllUsers = async () => {
  try {
    const querySnapshot = await getDocs(userCheckoutCollectionReference);
    const userInformation = [];

    querySnapshot.forEach((user) => {
      userInformation.push({ ...user.data(), id: user.id });
    });

    return userInformation;
  } catch (error) {
    console.error("An error occurred while fetching user data:", error);
    throw error; // Rethrow the error for the calling function to handle
  }
};

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Add a user to the database

const addUserToDatabase = (requestData) =>{
   addDoc(userCheckoutCollectionReference, requestData).then(() =>{
    return "Succesfully added user!"
  }).catch((err) =>{
    return console.log(err)
  })
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Retrieve a certain event from the database

const retrieveEventItemData = async (id) => { 
  try {
  const docRef = doc(db, "Events", id);
  const docs = await getDoc(docRef)
  return docs.data()
  } catch (error) {
    return "There is either not a matching event or the database of the application has issues. Please try again" + error.message
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Add a user to the Event-Session

const addUserToEventSession = async (guestObject) => {
  try { //maybe JSON.parse to guest
    const eventSessionDocRef = doc(eventSessionsCollectionReference, guestObject["eventName"])
    await setDoc(eventSessionDocRef, {})
    //creating an empty Document
    const guestNameRef = doc(eventSessionDocRef, 'guestName', guestObject["guestName"])
    await setDoc(guestNameRef, guestObject["guestData"])
    //Created a child Document which holds the Event Name and Guest Data 
  } catch (e) {
    console.error(`The following error occured: ${e}`)
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Upload a Muttizettel to the database

const addMuttizettel = async (formData) => {
  try {
    const muttizettelDocRef = doc(muttizettelCollectionReference, formData["underageName"])
    await setDoc(muttizettelDocRef, formData)
  } catch (err) {
    console.error("Error occured when adding a Muttizettel to the database: ", err)
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Get all the current Event-Sessions

const getEventSessions = async () => {
  try {
    const querySnapshot = await getDocs(eventSessionsCollectionReference)
    const eventSessions = []
    querySnapshot.forEach((eventSession) => {
      eventSessions.push(eventSession.id)
    })
    return eventSessions
  } catch (e) {
    console.error("Error occured, when fetching the EventSessions from the Database: ", e)
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Get the users from the Event-Sessions

const getGuestData = async(eventName) => {
  try {
    const guestDataCollectionReference = collection(db, `EventSessions/${eventName}/guestName`);
    const guestDataSnapshot = await getDocs(guestDataCollectionReference);

    const guests = [];

    guestDataSnapshot.forEach((guestDoc) => {
      guests.push(guestDoc.id);
    });
    return guests;
    
  } catch (e) {
    console.error("Error occured, when fetching the Guest Data from the Database: ", e)
  }
}
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports:

export { app, getAllUsers, addUserToDatabase, getEvents, retrieveEventItemData, addUserToEventSession, getEventSessions, getGuestData, addMuttizettel };
export const auth = getAuth(app);
