////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import "../styles/components.css";
import { useAuth } from "../contexts/AuthContext";
import Button from "react-bootstrap/Button";
import { useState } from "react";

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the NavBar component to navigate on the website
function NavBar() {

  const { currentUser, logout } = useAuth();
  const [error, setError] = useState()
  const [successMessage, setSuccessMessage] = useState()
  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Handling the logout with the firebase authentication
async function handleLogout(event) {
  setError("");
  event.preventDefault();
  try {
    await logout();
    setSuccessMessage("Logout was successful!");
  } catch (error) {
    setError("Failed to logout");
    console.log(error.message);
  }

}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

const isMobileDevice = () => {
  return navigator.maxTouchPoints > 0;
};

  //Displaying the currently logged-in user 
  function DisplayUser() {

    if (currentUser) {
      
      return (
        <>
        <div > 
         <p className="WelcomeMessage" style={{color: "#ffffff"}}>
            Welcome {currentUser.email}
          </p>
          </div>
        </>
          )
        }
      else {
        return;
    }
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Navbar sticky="top" expand="lg" variant="dark">
        <Container fluid>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "0.4rem",
                marginTop: "0.5rem",
              }}
              className="me-auto"
            >
              <button
                className="cta"
                onClick={() => (window.document.location = "/")}
              >
                <span class="hover-underline-animation">Home</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>
              <button
                className="cta"
                onClick={() => (window.document.location = "/muttizettel")}
              >
                <span class="hover-underline-animation">Muttizettel</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>
            </Nav>
            {!isMobileDevice() ? (<Nav
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                marginLeft: "13.7rem",
                marginTop: "0.5rem",
              }}
              className="me-auto"
            >
            <DisplayUser />
            </Nav>) : null}
            <Nav
              style={{
                display: "flex",
                alignItems: "start",
                justifyContent: "start",
                marginLeft: "0.4rem",
                marginTop: "0.4rem",
              }}
              className="justify-content-end"
            >
              {currentUser ? (<button
                onClick={handleLogout}
                class="cta"
              >
                <span class="hover-underline-animation">Logout</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>) : null }
              <button
                onClick={() => {
                  window.location.href = "/login";
                }}
                class="cta"
              >
                <span class="hover-underline-animation">Login</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>
              <button
                onClick={() => {
                  window.location.href = "/register";
                }}
                class="cta"
              >
                <span class="hover-underline-animation">Registrieren</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
       
      </Navbar>
      
      <hr
        style={{
          color: "white",
          boxShadow: "5px 0px 7px 10px rgb(25, 180, 222)",
          position: "sticky",
          top: "0",
          zIndex: "100",
        }}
      />
    </>
  );
}

export default NavBar;
