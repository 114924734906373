///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import "./App.css";
import Login from "./components/Login";
import { Route, Routes } from "react-router-dom";
import Register from "./components/Register";
import Home from "./components/Home";
import TicketData from "./components/TicketData";
import { AuthProvider } from "./contexts/AuthContext";
import Muttizettel from "./components/Muttizettel";
import CreateStripeSession from "./components/CreateStripeSession";
import { IDProvider } from "./contexts/TicketInformationProvider";
import SuccessedPayment from "./components/SuccessedPayment";
import CheckInGuest from "./components/CheckInGuest";
import Guestlist from "./components/Guestlist";
import NavBar from "./components/NavBar";
import SendMuttizettel from "./components/SendMuttizettel";
import Cancel from "./components/Cancel";
import ProtectedRoute from "./components/ProtectedRoute";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//App Tree with all the different components and their routes 
//Wrapping the IDProvider and AuthProvider Context around the application
function App() {
  return (
    <IDProvider>
      <AuthProvider>
        <NavBar></NavBar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ticketdata" element={<TicketData />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/muttizettel" element={<Muttizettel />} />
          <Route
            path="/createstripesession"
            element={<CreateStripeSession />}
          />
          <Route path="/successedpayment/:successID" element={<SuccessedPayment />} />
          <Route path="/checkinguest" element={<CheckInGuest />} />
          {/* Protecting the guestlist to be accessed by unauthenticated users */}
          <Route element={<ProtectedRoute />}>
            <Route path="/guestlist" element={<Guestlist />} />
          </Route>
          <Route path="/sendmuttizettel" element={<SendMuttizettel />} />
          <Route path="/cancel" element={<Cancel />} />
        </Routes>
      </AuthProvider>
    </IDProvider>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports
export default App;
