///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Imports
import { useAuth } from "../contexts/AuthContext";
import { useState, useEffect, useContext } from "react";
import { useID } from "../contexts/TicketInformationProvider";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Calculating the date of the past day in order to not show past events
var date = new Date();
let dd = date.getDate() - 1;
let mm = date.getMonth() + 1;
let yyyy = date.getFullYear();
let yesterday = `${dd}/${mm}/${yyyy}`;

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating a Component which validates and shows the EventCards
function EventCard({
  identifier,
  eventName,
  date,
  location,
  priceInEur,
  imgSrc,
}) {

  //Initializing the ticket information context and the updateTicketInformation procedure 
  const { ticketInformation, updateTicketInformation } = useID();

  if (yesterday === date) {
    return; // checks if the event is still remaining. If it passed the date, the event will not show up
  }

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining a procedure which updates the ticketinformation context as soon as the user chooses to buy a ticket for an event
  const updateTicketContext = async (e) => {
    await updateTicketInformation({
      eventID: identifier,
      eventName: eventName,
      guestName: "",
      emailAddress: "defaultName",
      hasSentEmail: false,
      age: 0,
      payment: false,
      priceInCents: priceInEur,
    });
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Redirecting the user to the ticketdata route
    window.location.href = "/ticketdata";
  };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


//Returning the mark up of the components
  return (
    <div className="cards">
      <img src={imgSrc} alt="PartyImage" style={{ borderRadius: "4px" }} />
      <p className="heading">{eventName}</p>
      <hr className="eventCardHr" />
      <p>
        {location} <br /> {date}
      </p>
      <hr className="eventCardHr" />
      <h5 style={{ marginRight: "1rem" }}>€ {priceInEur / 100}</h5>

      <button onClick={updateTicketContext} className="BuyTicketButton">
        Ticket kaufen!
      </button>
    </div>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Defining the actual home component
function Home() {
  //Defining state variables and initializing the ticketinformation  
  const { currentUser, logout } = useAuth();
  const { ticketInformation, updateTicketInformation } = useID();
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  //Fetching all the valid events as soon as the component mounts
  useEffect(() => {
    fetch(`${process.env.REACT_APP_SERVER_API_URL}/events`)
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        }
        const json = await response.json();
        return await Promise.reject(json);
      })
      .then((data) => setEvents(data))
      .catch((error) => console.error(error.message));
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Clearing the ticketinformation context when the user is redirected to the homepage
    updateTicketInformation({
      eventID: "",
      eventName: "",
      guestName: "",
      emailAddress: "defaultEmail",
      hasSentEmail: false,
      age: 0,
      payment: false,
      priceInCents: 0,
      imgSrc: events.imgSrc,
    });
  }, []);

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Setting up the configurations for the responsive carusel layout of the event cards
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Returning the mark up to the component
  return (
    <div className="container">
      <h1
        style={{
          color: "#e3e3e3",
          marginBottom: "45px",
        }}
      >
        <u style={{ marginLeft: "10%", textDecoration: "none" }}>
          Kommende Events
        </u>
        &#x26A1;
      </h1>
      <Carousel responsive={responsive}>
        {events.map((event) => (
          <div key={event.id}>
            <EventCard
              identifier={event.eventID}
              eventName={event.eventName}
              date={event.date}
              location={event.location}
              priceInEur={event.priceInCents}
              imgSrc={event.imgSrc}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//exports
export default Home;
export { EventCard };
