import React, { createContext, useState, useContext, useEffect } from "react";

const IDContext = createContext();

const IDProvider = ({ children }) => {
  const storedTicketInformation = localStorage.getItem("ticketInformation");
  const [ticketInformation, setTicketInformation] = useState(
    storedTicketInformation
      ? JSON.parse(storedTicketInformation)
      : {
          eventID: "defaultId",
          eventName: "eventName",
          guestName: "defaultValue",
          emailAddress: "defaultEmail",
          hasSentEmail: false,
          age: 0,
          payment: false,
          priceInCents: 0,
          imgSrc: "",
        }
  );

  useEffect(() => {
    localStorage.setItem(
      "ticketInformation",
      JSON.stringify(ticketInformation)
    );
  }, [ticketInformation]);

  const updateTicketInformation = (newData) => {
    setTicketInformation((prevData) => ({
      ...prevData,
      ...newData,
    }));
  };

  const contextValue = { ticketInformation, updateTicketInformation };

  return (
    <IDContext.Provider value={contextValue}>{children}</IDContext.Provider> //Renders the children components with the value of ContextValue
  );
};

const useID = () => {
  const context = useContext(IDContext);
  if (!context) {
    throw new Error("useID must be used within an IDProvider");
  }
  return context;
};

export { IDProvider, useID };
