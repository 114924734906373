///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Creating a Page, for the guest if the payment was not successful

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

function Cancel(){
    return (
        <>
        <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}> 
        <h1 style={{color: "#ffffff"}}>Sorry, the payment was not successful! <br />
            Please try again    <br />
        </h1>
        </div>
        <div style={{display: "flex", alignItems: "center", justifyContent: "center", marginTop: "2%"}}>
        <button
                className="cta"
                onClick={() => (window.document.location = "/")}
              >
                <span class="hover-underline-animation">Home</span>
                <svg
                  id="arrow-horizontal"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="10"
                  viewBox="0 0 46 16"
                >
                  <path
                    id="Path_10"
                    data-name="Path 10"
                    d="M8,0,6.545,1.455l5.506,5.506H-30V9.039H12.052L6.545,14.545,8,16l8-8Z"
                    transform="translate(30)"
                  ></path>
                </svg>
              </button>
        </div>
        </>
    )
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

//Exports
export default Cancel